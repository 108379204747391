import React, {useEffect, useState, useRef} from 'react';
import './style.scss';
import media from '../../media';

export default function VideoScreen(props) {
    const [videoOverlay, setVideoOverlay] = useState(true);
    const [closeBtn, setCloseBtn] = useState(false);
    const [videoControls, setVideoControls] = useState(false);
    const [videoMuted, setVideoMuted] = useState(true);

    const refVideo = useRef(null);

    return (
        <div className={"video-screen"+((videoOverlay===false&&props.playOnFs!==false)?" fullscreen":"")}>
            <video className="main-video" ref={refVideo} controls={videoControls} controlsList="nodownload" autoPlay={(props.content.hasOwnProperty("short")===false&&videoOverlay===true)} muted={videoMuted} onEnded={onVideoEnded}>
                <source src={media[props.content.src]} type="video/mp4" />
            </video>

            {(props.content.hasOwnProperty("short")===true && videoOverlay===true) &&
                <video className="short-video" controls={false} autoPlay={true} muted={true} loop={true}>
                    <source src={media[props.content.short]} type="video/mp4" />
                </video>
            }

            {videoOverlay &&
                <div className="overlay">
                    <div className="inner-content">
                        <div className={"overlay-elements"+(props.content.svg?"":" no-svg")}>
                            {props.content.svg && <img className="svg" src={media[props.content.svg]}/>}

                            <div className="play-btn" onClick={onPlayClick}>
                                <div className="play-bg"></div>
                                <div className="play-border"></div>
                                <div className="play-content">
                                    <span className="arrows">
                                        <span className="arrow"></span>
                                        <span className="arrow"></span>
                                    </span>
                                    <span className="label" dangerouslySetInnerHTML={{__html:props.content.btn}}></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            {(closeBtn && props.playOnFs!==false) && <div className="close-btn" onClick={onCloseClick}></div>}
        </div>
    );

    function playVideo() {
        refVideo.current.pause();
        refVideo.current.currentTime = 0;
        refVideo.current.play();

        setVideoOverlay(false);
        setCloseBtn(true);
        setVideoControls(true);
        setVideoMuted(false);

        if (props.onVideoPlay) {
            props.onVideoPlay();
        }
    }

    function stopVideo() {
        refVideo.current.pause();
        refVideo.current.currentTime = 0;

        if (props.content.hasOwnProperty("short")===false) {
            refVideo.current.play();
        }

        setVideoOverlay(true);
        setCloseBtn(false);
        setVideoControls(false);
        setVideoMuted(true);

        if (props.onVideoStop) {
            props.onVideoStop();
        }
    }

    function onPlayClick(e) {
        playVideo();
    }

    function onVideoEnded(e) {
        stopVideo();
    }

    function onCloseClick(e) {
        stopVideo();
    }
}
